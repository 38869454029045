import Storage from "./storage";
export default {
  getServe() {
    if (process.env.NODE_ENV === "production") {
      return "https://dgfmapi.minedu.gob.bo/";
    } else {
      return "http://localhost:4000/";
    }
  },
  getBase() {
    return this.getServe() + "api/";
  },
  getTokenMap() {
    return "pk.eyJ1Ijoic2lnZWUiLCJhIjoiY2p5c3FmOWgxMG44ajNsbG0weTdmbHFqMCJ9.xzWqmSJjMqpM86r1Kf1jhg";
  },
  setToken(token) {
    return sessionStorage.setItem("token", token);
  },
  getToken() {
    return sessionStorage.getItem("token") || "null";
  },
  removeToken() {
    return sessionStorage.removeItem("token");
  },
  getHeader() {
    return {
      headers: {
        Accept: "application/json",
        Authorization: Storage.getToken(),
      },
    };
  },
  getFileHeader() {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Storage.getToken(),
      },
    };
  },
};
