<template>
  <div id="tipy-regulation">
    <v-container grid-list-xl fluid>
      <header-title title="Tipo Normativa"></header-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="type_regulations"
        :loading="loading"
        :search="search"
        calculate-widths
        no-data-text="No existen registros"
        no-results-text="Sin resultados"
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.publico`]="{ item }">
          <td>
            <span
              v-if="
                item.publico == 1 ||
                  item.publico == true ||
                  item.publico == 'true'
              "
            >
              <v-chip small color="green lighten-4">si</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">no</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmItem(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="formDialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Tipo normativa</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    type="text"
                    label="Descripción *"
                    v-model="type_regulation.descripcion"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    label="Público"
                    :items="status"
                    v-model="type_regulation.publico"
                    item-text="description"
                    item-value="id"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    label="Estado"
                    :items="status"
                    v-model="type_regulation.estado"
                    item-text="description"
                    item-value="id"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createTypeRegulation()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateTypeRegulation()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="formDialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteItem()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="confirmDialog = false"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Storage from "../utils/storage";
import Service from "../services/request";
import { mapActions } from "vuex";
export default {
  name: "type-regulation",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      loading: false,
      btn_loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Descripción ", value: "descripcion" },
        { text: "Público ", value: "publico" },
        { text: "Estado ", value: "estado" },
        { text: "Acciones", value: "actions", sortable: false, width: "18%" },
      ],
      type_regulations: [],
      type_regulation: {
        id: "",
        descripcion: "",
        publico: "",
        estado: "",
      },
      status: [],
      formDialog: false,
      confirmDialog: false,
      mode: true,
    };
  },
  mounted() {
    if (Storage.getUser()) {
      if (Storage.getUser().role == 1) {
        this.status = Service.getStatus();
        this.getTypeRegulations();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions(["toast"]),
    getTypeRegulations() {
      this.loading = true;
      Service.svcTypeRegulation("get")
        .then((response) => {
          this.loading = false;
          this.type_regulations = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showDialog() {
      this.type_regulation = {
        id: "",
        descripcion: "",
        publico: false,
        estado: true,
      };
      this.mode = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.formDialog = true;
    },
    createTypeRegulation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcTypeRegulation("post", this.type_regulation)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.formDialog = false;
            this.getTypeRegulations();
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    editItem(data) {
      this.type_regulation = data;
      this.mode = false;
      this.formDialog = true;
    },

    updateTypeRegulation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcTypeRegulation("put", this.type_regulation)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.formDialog = false;
            this.getTypeRegulations();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast({ color: "error", text: "No se pudo actualizar" });
          });
      }
    },

    confirmItem(id) {
      this.type_regulation.id = id;
      this.confirmDialog = true;
    },

    deleteItem() {
      this.btn_loading = true;
      Service.svcTypeRegulation("delete", this.type_regulation)
        .then((response) => {
          this.btn_loading = false;
          if (response.status === 204) {
            this.confirmDialog = false;
            this.toast({ color: "success", text: "Registro eliminado" });
            this.getTypeRegulations();
          } else {
            this.toast({
              color: "error",
              text: "Registro en uso o no existe",
            });
          }
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast({
            color: "error",
            text: "Registro en uso o no existe",
          });
        });
    },
  },
};
</script>
