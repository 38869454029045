import endpoint from "../utils/environment";
// import Storage from "../utils/storage";
import axios from "axios";

/* axios.defaults.headers = {
  Accept: "application/json",
  Authorization: "Bearer " + Storage.getToken(),
}; */
const axio = axios.create({
  baseURL: endpoint.getServe(),
  //timeout: 2000,
});

/* axio.interceptors.response.use(
  (response) => {
    console.log(1111111, response);
    if ([200, 201, 204].includes(response.status)) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      console.log(error.response.status);
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          alert("session expired");
          break;
        case 403:
          // router.replace({
          //   path: "/signin",
          //   query: { redirect: router.currentRoute.fullPath },
          // });
          break;
        case 404:
          alert("page not exist");
          break;
        case 502:
        // setTimeout(() => {
        //     router.replace({
        //       path: "/signin",
        //       query: {
        //         redirect: router.currentRoute.fullPath,
        //       },
        //     });
        //   }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
); */

export default {
  svcSignIn(payload) {
    return new Promise((resolve, reject) => {
      axio
        .post("usuario/auth", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getStatus() {
    return [
      { id: true, description: "Activo" },
      { id: false, description: "No activo" },
    ];
  },
  getYears() {
    let years = [],
      min = 2015,
      max = new Date().getFullYear();
    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  },
  // MANAGEMENT
  async normalCRUD(route, method, payload) {
    const normalHeader = endpoint.getHeader();
    let result = null;
    try {
      switch (method) {
        case "post":
          result = await axio.post(route, payload, normalHeader);
          break;
        case "put":
          result = await axio.put(
            `${route}/${payload.id}`,
            payload,
            normalHeader
          );
          break;
        case "delete":
          result = await axio.delete(`${route}/${payload.id}`, normalHeader);
          break;
        default:
          result = await axio.get(route, normalHeader);
          break;
      }
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fileCRUD(route, method, payload, id) {
    const fileHeader = endpoint.getFileHeader();
    let result = null;
    try {
      switch (method) {
        case "post":
          result = await axio.post(route, payload, fileHeader);
          break;
        case "put":
          result = await axio.put(`${route}/${id}`, payload, fileHeader);
          break;
        default:
          break;
      }
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  svcTypeRegulation(method, payload = null) {
    const route = "tipo/normativa";
    return this.normalCRUD(route, method, payload);
  },
  svcTypeRegulationParam(method, payload = null) {
    const route = "parametro/tipo/normativa";
    return this.normalCRUD(route, method, payload);
  },
  /* svcRegulation(method, payload = null) {
    const route = "normativa";
    return this.normalCRUD(route, method, payload);
  }, */
  svcRegulation(method, payload = null, file = null) {
    let route = "normativa";
    if (["post", "put"].includes(method) && file) {
      route = "normativaf";
      let payloadf = new FormData();
      payloadf.append("cite", payload.cite);
      payloadf.append("descripcion", payload.descripcion);
      payloadf.append("tipo_normativa_id", payload.tipo_normativa_id);
      payloadf.append("usuario_id", payload.usuario_id);
      payloadf.append("estado", payload.estado);
      payloadf.append("location", payload.location);
      payloadf.append("documento", payload.documento);

      return this.fileCRUD(route, method, payloadf, payload.id);
    }
    return this.normalCRUD(route, method, payload);
  },

  svcActivity(method, payload = null, file = null) {
    let route = "api/gerencia/actividad";
    if (["post", "put"].includes(method) && file) {
      route = "api/gerencia/actividadf";
      let payloadf = new FormData();
      payloadf.append("tipo_informacion_id", payload.tipo_informacion_id);
      payloadf.append("roles_id", JSON.stringify(payload.roles_id));
      payloadf.append("descripcion", payload.descripcion);
      payloadf.append("fecha_limite", payload.fecha_limite);
      payloadf.append("location", payload.location);
      payloadf.append("estado", payload.estado);
      payloadf.append("plantilla", payload.plantilla);

      return this.fileCRUD(route, method, payloadf, payload.id);
    }
    return this.normalCRUD(route, method, payload);
  },
};
