export default {
  strencdesc(s, k) {
    var enc = "";
    for (var i = 0; i < s.length; i++) {
      var a = s.charCodeAt(i);
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }
    return enc;
  },
  getServe() {
    if (process.env.NODE_ENV === "production") {
      return "https://dgfmapi.minedu.gob.bo";
    } else {
      return "http://localhost:4000";
    }
  },
  getBase() {
    return this.getServe() + "/";
  },
  getTokenMap() {
    return "pk.eyJ1Ijoic2lnZWUiLCJhIjoiY2p5c3FmOWgxMG44ajNsbG0weTdmbHFqMCJ9.xzWqmSJjMqpM86r1Kf1jhg";
  },
  setToken(token) {
    return sessionStorage.setItem("token", token);
  },
  getToken() {
    return "Bearer " + sessionStorage.getItem("token");
  },
  removeToken() {
    return sessionStorage.removeItem("token");
  },
  setUser(user) {
    return localStorage.setItem(
      "uwjrax",
      this.strencdesc(
        JSON.stringify({
          uid: user.ius,
          name: user.name,
          role: user.iro,
          rol: user.rol,
        }),
        3
      )
    );
  },
  getUser() {
    return localStorage.getItem("uwjrax")
      ? JSON.parse(this.strencdesc(localStorage.getItem("uwjrax"), 3))
      : null;
  },
  removeUser() {
    return localStorage.removeItem("uwjrax");
  },
  /* setMenu(menu) {
    return sessionStorage.setItem(
      "friwo",
      this.strencdec(JSON.stringify(menu), 3)
    );
  },
  getMenu() {
    return sessionStorage.getItem("friwo")
      ? JSON.parse(this.strencdec(sessionStorage.getItem("friwo"), 3))
      : null;
  },
  removeMenu() {
    return sessionStorage.removeItem("friwo");
  }, */
};
